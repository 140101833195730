import Layout from "@/layout/Container.vue"
import { clear, getStorage } from "../storage"

export const commonRouter = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login")
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: "工作台"
    },
    component: Layout,
    children: [
      {
        path: '/home/index',
        name: 'HomeIndex',
        meta: {
          title: '数据总览',
          parent: '工作台'
        },
        component: () => import("@/views/home/index")
      },
      {
        path: '/home/census',
        name: 'HomeCensus',
        meta: {
          title: '零工统计',
          parent: '工作台'
        },
        component: () => import("@/views/home/census")
      }
    ]
  },
  {
    path: '/zerojob',
    name: 'zerojob',
    meta: {
      title: '零工管理'
    },
    component: Layout,
    children:[
      {
        path: '/zerojob/recruit',
        name: 'RecruitList',
        meta: {
          title: '招工列表',
          parent: '零工管理'
        },
        component: () => import("@/views/zerojob/recruit/index.vue")
      },
      {
        path: '/zerojob/order',
        name: 'OrderList',
        meta: {
          title: '工单列表',
          parent: '零工管理'
        },
        component: () => import("@/views/zerojob/order/index.vue")
      }
    ]
  },
  {
    path: '/user',
    name: 'User',
    meta: {
      title: '用户管理'
    },
    component: Layout,
    children:[
      {
        path: '/user/list',
        name: 'UserList',
        meta: {
          title: '用户列表',
          parent: '用户管理'
        },
        component: () => import("@/views/user/list")
      },
      {
        path: '/user/ranking',
        name: 'UserRankingt',
        meta: {
          title: '零工排名',
          parent: '用户管理'
        },
        component: () => import("@/views/user/ranking")
      }
    ]
  },
  {
    path: '/banner',
    name: 'Banner',
    meta: {
      title: "轮播图管理",
    },
    component: Layout,
    children: [
      {
        path: '/banner/list',
        name: 'BannerList',
        meta: {
          title: '轮播图列表',
          parent: '轮播图管理'
        },
        component: () => import("@/views/banner/list")
      },
    ]
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      title: "账号管理",
    },
    component: Layout,
    children: [
      {
        path: '/account/list',
        name: 'AccountList',
        meta: {
          title: '账号列表',
          parent: '账号管理'
        },
        component: () => import("@/views/account/list")
      },
    ]
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: '零工资讯'
    },
    component: Layout,
    children:[
      {
        path: '/news/list',
        name: 'NewsList',
        meta: {
          title: '新闻资讯',
          parent: '零工资讯'
        },
        component: () => import("@/views/news/index")
      }
    ]
  },
  {
    path: '/guide',
    name: 'Guide',
    meta: {
      title: "使用指南",
    },
    component: Layout,
    children: [
      {
        path: '/guide/list',
        name: 'GuideList',
        meta: {
          title: '指南列表',
          parent: '使用指南'
        },
        component: () => import("@/views/guide/index")
      }
    ]
  },
 
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]
