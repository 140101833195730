import Vue from 'vue';
import {
  Aside,
  Avatar,
  Button,
  Col,
  Container,
  Form,
  FormItem,
  Header,
  Input,
  InputNumber,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Row,
  Submenu,
  Table,
  TableColumn,
  MessageBox,
  Loading,
  Message,
  Step,
  Steps,
  DatePicker,
  Select,
  Option,
  OptionGroup,
  Cascader,
  Upload,
  Dialog,
  Progress,
  Descriptions,
  DescriptionsItem,
  Pagination,
  Tree,
  Switch,
  Radio,
  RadioGroup,
  RadioButton,
  Tooltip,
  Tag,
  Popover,
  Image,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Divider,
  Breadcrumb,
  BreadcrumbItem,
  Link,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  PageHeader,
} from "element-ui"
import eIconPicker from 'e-icon-picker'
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import 'e-icon-picker/lib/index.css'; // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库

// import "element-ui/lib/theme-chalk/index.css"
Vue.use(eIconPicker, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true,//自带的图标，来自阿里妈妈
  eIconSymbol: false,//是否开启彩色图标
  zIndex: 2200 //选择器弹层的最低层,全局配置
})
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message

const components = [
  Container,
  Header,
  Main,
  Aside,
  Form,
  FormItem,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Avatar,
  Submenu,
  Menu,
  MenuItem,
  MenuItemGroup,
  Table,
  TableColumn,
  Loading.directive,
  Steps,
  Step,
  DatePicker,
  Select,
  Option,
  OptionGroup,
  Cascader,
  Upload,
  Dialog,
  Progress,
  Descriptions,
  DescriptionsItem,
  Pagination,
  Tree,
  Switch,
  Radio,
  RadioGroup,
  RadioButton,
  Tooltip,
  Tag,
  Popover,
  eIconPicker,
  Image,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Divider,
  Breadcrumb,
  BreadcrumbItem,
  Link,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  PageHeader
]
components.forEach(item => Vue.use(item))
